import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "bouwkennis"
    }}>{`BouwKennis`}</h1>
    <h2 {...{
      "id": "project-outline"
    }}>{`Project outline`}</h2>
    <p>{`BouwKennis is a company that creates (strategic) marketing information out of data from sources like CBS, BAG and other (custom) inquiries or research. This data is then churned into usable information for various customers in the construction sector. HybrIT helped BouwKennis to setup a BI solution together with a customer portal. Data loaded into the BI solution is queried by PowerBI and these PowerBI reports are then shown through the portal to the customer.`}</p>
    <h2 {...{
      "id": "architecture"
    }}>{`Architecture`}</h2>
    <p>{`The orange bordered box represents the portal, the green bordered box represents the BI solution
and the blue bordered box includes all other dependent services.`}</p>
    <p><img alt="Logical architecture" src={require("./images/bouwkennis-logical-architecture.png")} /></p>
    <h2 {...{
      "id": "coding--techniques"
    }}>{`Coding & techniques`}</h2>
    <ul>
      <li parentName="ul">{`Frontend build in Angular 8.x`}</li>
      <li parentName="ul">{`Reporting API build in .NET core (3.1), swagger docs included`}</li>
      <li parentName="ul">{`Docker & helm charts`}</li>
      <li parentName="ul">{`Using GitVersion to auto-version artifacts`}</li>
      <li parentName="ul">{`Azure DevOps pipeline definitions`}</li>
      <li parentName="ul">{`Keycloak as OAuth/OpenID server`}</li>
      <li parentName="ul">{`PostgreSQL for reporting API and OAuth/OpenID server (different db's ofcourse)`}</li>
      <li parentName="ul">{`Using git secret to encrypt sensitive information like configuration files`}</li>
      <li parentName="ul">{`PowerBI REST API (`}<a parentName="li" {...{
          "href": "https://docs.microsoft.com/en-us/rest/api/power-bi/"
        }}>{`https://docs.microsoft.com/en-us/rest/api/power-bi/`}</a>{`)`}</li>
      <li parentName="ul">{`Azure Data Factory for defining and running BI pipelines`}</li>
      <li parentName="ul">{`Azure SQL (MSSQL) for output of BI pipelines`}</li>
      <li parentName="ul">{`Azure Storage for saving static reports & and saving input and output of BI pipelines`}</li>
      <li parentName="ul">{`Terraform to setup all (cloud) infrastructure`}</li>
    </ul>
    <h2 {...{
      "id": "environments"
    }}>{`Environments`}</h2>
    <p>{`There is a default (O)TAP in place. Doing a git pull and running it will connect to keycloak on test and the reporting API on test.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Test`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Staging`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Production`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://test.bouwkennis.hybrit.dev"
            }}>{`https://test.bouwkennis.hybrit.dev`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://staging.bouwkennis.hybrit.dev"
            }}>{`https://staging.bouwkennis.hybrit.dev`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://bouwkennis.hybrit.dev"
            }}>{`https://bouwkennis.hybrit.dev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Keycloak`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://test.bouwkennis.hybrit.dev/auth"
            }}>{`https://test.bouwkennis.hybrit.dev/auth`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://staging.bouwkennis.hybrit.dev/auth"
            }}>{`https://staging.bouwkennis.hybrit.dev/auth`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://bouwkennis.hybrit.dev/auth"
            }}>{`https://bouwkennis.hybrit.dev/auth`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://test.bouwkennis.hybrit.dev/reporting/docs/swagger"
            }}>{`https://test.bouwkennis.hybrit.dev/reporting/docs/swagger`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://staging.bouwkennis.hybrit.dev/reporting/docs/swagger"
            }}>{`https://staging.bouwkennis.hybrit.dev/reporting/docs/swagger`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://bouwkennis.hybrit.dev/reporting/docs/swagger"
            }}>{`https://bouwkennis.hybrit.dev/reporting/docs/swagger`}</a></td>
        </tr>
      </tbody>
    </table>
    <p>{`In production bouwkennis has the following CNAME configurations in their DNS:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://app.bouwkennis.nl"
        }}>{`https://app.bouwkennis.nl`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://app.bauinfoconsult.de"
        }}>{`https://app.bauinfoconsult.de`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://app.buildinfoconsult.com"
        }}>{`https://app.buildinfoconsult.com`}</a>{` `}</li>
    </ul>
    <p>{`All environments run in one k8s cluster (there is no high demand for resources) seperated by namespaces. The kubernetes cluster runs in Azure and is named `}<inlineCode parentName="p">{`bouwkennis-weu`}</inlineCode>{`. The k8s cluster is seeded with an nginx ingress controller and certificate manager.`}</p>
    <h2 {...{
      "id": "repositories"
    }}>{`Repositories`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Repository`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BouwKennis portal which contains all the front-end code.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/bouwkennis-portal-frontend-angular"
            }}>{`https://github.com/HybrIT-dev/bouwkennis-portal-frontend-angular`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reporting API`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BouwKennis Reporting API for the portal and connection with powerBI.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/bouwkennis-reporting-api-dotnet"
            }}>{`https://github.com/HybrIT-dev/bouwkennis-reporting-api-dotnet`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Keycloak`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Implementation of Keycloak with specific BouwKennis theme. This repository also holds the identity API (abstraction over keycloak for users/clients) and the helm charts needed to deploy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/bouwkennis-identity-keycloak"
            }}>{`https://github.com/HybrIT-dev/bouwkennis-identity-keycloak`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Infrastructure`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The infrastructure (terraform) used to roll out all cloud resources needed to run.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/bouwkennis-infrastructure"
            }}>{`https://github.com/HybrIT-dev/bouwkennis-infrastructure`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BAG Importer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A console app which automatically downloads and processes BAG information.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/bouwkennis-bagimporter-dotnet"
            }}>{`https://github.com/HybrIT-dev/bouwkennis-bagimporter-dotnet`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Data Factory`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used by Azure Data Factory to keep a safe copy & history about changes in all BI pipelines.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/bouwkennis-datafactory"
            }}>{`https://github.com/HybrIT-dev/bouwkennis-datafactory`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "functionality"
    }}>{`Functionality`}</h2>
    <h3 {...{
      "id": "user-roles"
    }}>{`User roles`}</h3>
    <p>{`Within the portal the following roles are used:`}</p>
    <ol>
      <li parentName="ol">{`Administrator`}</li>
      <li parentName="ol">{`Company Owner`}</li>
      <li parentName="ol">{`(Company) User`}</li>
      <li parentName="ol">{`Workspace Manager`}</li>
    </ol>
    <h3 {...{
      "id": "user-registration-flows"
    }}>{`User registration flows`}</h3>
    <p>{`User accounts arise in the portal in 2 ways (A company owner is invited by an admin user):
1. A user registers him/her selves (Flow A)
2. A user is invited by a company owner (Flow C)`}</p>
    <p>{`When a user that has registered wants to join a company, the company owner needs to accept the join request (Flow B).`}</p>
    <p><img alt="Registration diagrams" src={require("./images/bouwkennis-user-diagrams.png")} />{`
`}<img alt="Report Authorisation diagram" src={require("./images/Bouwkennis-Reports-authorisation-flow.png")} /></p>
    <h3 {...{
      "id": "report-creation"
    }}>{`Report creation`}</h3>
    <p>{`Users with the role Administrator assigned are allowed to manage reports within the portal.`}</p>
    <p>{`To do so, navigate to Manage Reports/Digital information.
From here you can manage existing reports or create new ones, you can sync with PowerBI to get their latest reports and you can authorise reports in bulk.`}</p>
    <p>{`To create a report, press the '+' icon on the top right of the screen.
1. Fill in the required fields
a. Title
i. Name of the report.
b. Language selection
2. Fill in the rest of the optional fields.
a. Keywords
I. To help find your report in search fields
b. Description
I. Additional information about the report
c. Categories
I. sorts the report into the right category on the "all reports/digital information" page
d. Prize of the report
e. Number of respondents
f. Choose a date
g. Product URL
I. web path to the report or other
h. Visible
I. Decide wether report is visible to any organisation
i. Can be requested by all users
I. Decide wether all users are able to purchase this report.
j. All users (who have acces to the report) will be notified by e-mail when this report is changed
I. One time usage, upon saving notifies the users that have access.
II. unchecks after saving
3. upload an image
4. upload a report / file
5. don't forget to press the 'save' button at the bottom right of your screen`}</p>
    <h3 {...{
      "id": "report-authorisation-visibility"
    }}>{`Report authorisation (visibility)`}</h3>
    <p>{`In User Management you can authorize users to a report.
Before you can authorise a user to a report, the report needs to be set to "visible" (see Report Creation 2.h.I).`}</p>
    <p>{`Once the report is set to visible, navigate to User Management, from there select the Account Owner of a Company, navigate to reports/digital information
here you manage which reports the Account Owner is authorised to, once authorised `}<strong parentName="p">{`all Users of that organisation will be automatically authorised to the report.`}</strong>{`
currently, a user of the same company can only see the report at "All reports", but has no access to it.`}</p>
    <p>{`To allow the user access to the report the Account owner must then individually assign access to the report in Organisation Management -> Select User -> Reports`}</p>
    <h3 {...{
      "id": "report-purchase"
    }}>{`Report purchase`}</h3>
    <p>{`When a user is allowed to purchase a report, and does so, it will automatically be assigned to the Account Owner, and thus to the rest of the Company Users.
The Account Owner must still individually grant access to the report.`}</p>
    <h3 {...{
      "id": "groups"
    }}>{`Groups`}</h3>
    <p>{`In Groups you can group reports together,
this allows you to assigned multiple reports at once to a certain type of Account Owner and speeds up the process of report authorisation,
individual authorisation in Organisation Management by the Account Owner will still be required.`}</p>
    <h3 {...{
      "id": "workspaces"
    }}>{`Workspaces`}</h3>
    <p>{`A Workspace Manager can assign a workspace to a User, this causes reports from the same workspace to be shown from powerBI at the 'All Reports' page and at the 'User details' page.
There are only 2 workspaces currently available: Bouwkennis and Euro-mat
By default the "Bouwkennis" workspace is used.`}</p>
    <h2 {...{
      "id": "keycloak-bouwkennis---euro-mat-settings"
    }}>{`Keycloak Bouwkennis - Euro-mat settings`}</h2>
    <p>{`Euro-mat uses a user from keycloak to connect to the reporting api in order to get reports.`}</p>
    <p>{`they connect to the client "Account" and the following steps need to be taken:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Get the access token from Keycloak using the right credentials.
Staging Url: `}<a parentName="p" {...{
            "href": "https://staging.bouwkennis.hybrit.dev/auth/realms/master/protocol/openid-connect/token"
          }}>{`https://staging.bouwkennis.hybrit.dev/auth/realms/master/protocol/openid-connect/token`}</a>{`
Production Url: `}<a parentName="p" {...{
            "href": "https://app.bouwkennis.nl/auth/realms/master/protocol/openid-connect/token"
          }}>{`https://app.bouwkennis.nl/auth/realms/master/protocol/openid-connect/token`}</a></p>
        <ol parentName="li">
          <li parentName="ol">{`grant_type: password`}</li>
          <li parentName="ol">{`audience: account `}</li>
          <li parentName="ol">{`username: (See lastpass - BKS Euromat gebruiker)`}</li>
          <li parentName="ol">{`password: (See lastpass - BKS Euromat gebruiker)`}</li>
          <li parentName="ol">{`Authorization: basic authentication:`}</li>
          <li parentName="ol">{`user: account `}</li>
          <li parentName="ol">{`staging password: (See lastpass - BKS Euromat gebruiker)`}</li>
          <li parentName="ol">{`production password: (See lastpass - BKS Euromat gebruiker)`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Getting a specific report that this user has access to:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`Methode: GET`}</li>
          <li parentName="ol">{`staging url: `}<a parentName="li" {...{
              "href": "https://staging.bouwkennis.hybrit.dev/reporting/api/me/reports/(report"
            }}>{`https://staging.bouwkennis.hybrit.dev/reporting/api/me/reports/(report`}</a>{` id)`}</li>
          <li parentName="ol">{`production url: `}<a parentName="li" {...{
              "href": "https://app.bouwkennis.nl/reporting/api/me/reports/(report"
            }}>{`https://app.bouwkennis.nl/reporting/api/me/reports/(report`}</a>{` id)`}</li>
          <li parentName="ol">{`Headers:`}</li>
          <li parentName="ol">{`Authorization: Bearer+(spacebar)+(access_token)`}</li>
        </ol>
      </li>
    </ol>
    <h3 {...{
      "id": "keycloak-settings"
    }}>{`Keycloak settings:`}</h3>
    <ol>
      <li parentName="ol">{`Clients -> account -> scope -> "Full Scope Allowed" requires to be on to prevent "unauthorized client" errors.`}</li>
      <li parentName="ol">{`Clients -> account -> Settings -> "Direct Access Grants Enabled" requires to be on to prevent "client not allowed direct access grants" errors`}</li>
    </ol>
    <h3 {...{
      "id": "maintenance-mode"
    }}>{`Maintenance Mode`}</h3>
    <p>{`Use kubectl and get the pods: kubectl get po`}</p>
    <p>{`kubectl config view --minify to see what context you're on, prod/staging/test`}</p>
    <p>{`kubectl get ns`}</p>
    <p>{`kubectl config set-context --current --namespace `}{`[namespace]`}</p>
    <p>{`kubectl get configmaps`}</p>
    <p>{`kubectl get configmaps bks-portal-frontend-angular -o yaml.`}</p>
    <p>{`now you see the configfile`}</p>
    <p>{`to edit the config file: kubectl edit configmaps bks-portal-frontend-angular`}</p>
    <p>{`I for Insert to type, `}</p>
    <p>{`Escape to stop typing`}</p>
    <p>{`":" to write a command, and type WQ " Write, Quit ";`}</p>
    <p>{`restart pod or hard reload (ctrl f5)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      